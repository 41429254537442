import { emptyClientPriority, emptyParameters } from "pages/store/consts";
import { TStoreParameters } from "types/configFields";

import { cardIssuerActionsMapper, replaceWithInitial } from "../helpers";

export const parametersMapper = (parameters: TStoreParameters | null) => {
  if (!parameters) {
    return emptyParameters;
  }

  const mappedCardIssuerActions = cardIssuerActionsMapper(
    parameters?.cardIssuerActions,
    true
  );

  return {
    ...parameters,
    clientPriority: replaceWithInitial(
      emptyClientPriority,
      parameters?.clientPriority
    ),
    sms: {
      ...parameters?.sms,
      prefixConfig: replaceWithInitial(
        { defaultChannel: "" },
        parameters?.sms?.prefixConfig
      )
    },
    cardIssuerActions: mappedCardIssuerActions
  };
};
