import {
  Box,
  ChakraProps,
  FormControl,
  FormErrorMessage,
  FormLabel
} from "@chakra-ui/react";
import { useField } from "formik";

type TProps = {
  isCheckbox?: boolean;
  isTouched?: boolean;
  label?: string;
  labelSize?: string;
  errorMessage?: string;
  children: React.ReactNode;
  name: string;
} & ChakraProps;

export function FieldWrapper({
  isCheckbox,
  label,
  labelSize = ".8rem",
  errorMessage,
  children,
  name,
  maxW,
  ...restProps
}: TProps) {
  const [, { touched: isTouched }] = useField(name);

  return (
    <FormControl
      isInvalid={isTouched && !!errorMessage}
      maxW={maxW}
      data-searchid={name}
    >
      <Box
        {...(isCheckbox && {
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem"
        })}
        {...restProps}
      >
        {label && (
          <FormLabel fontSize={labelSize} m={isCheckbox ? "0" : "initial"}>
            {label}
          </FormLabel>
        )}
        {children}
      </Box>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
