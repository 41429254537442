import * as Yup from "yup";

import {
  EComponent,
  EComponentProperty,
  EPosType,
  EMaintenanceBreak,
  EMonitor
} from "types/configFields";

import { provideNumberMessage, requiredMessage } from "./consts";

const boundaries100Error = "Ustaw wartość pomiędzy 0 a 100";

const monitorSchema = Yup.object()
  .nullable()
  .shape({
    [EMonitor.IS_ENABLED]: Yup.boolean().optional(),
    [EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS]: Yup.boolean(),
    [EMonitor.ACTIONS]: Yup.object()
      .nullable()
      .shape({
        [EMonitor.STORE_STATE_MODIFIER]: Yup.object().shape({
          [EMonitor.IS_ACTIVE]: Yup.boolean(),
          [EMonitor.CLOSE_THRESHOLD]: Yup.number()
            .typeError(provideNumberMessage)
            .min(0, boundaries100Error)
            .max(100, boundaries100Error),
          [EMonitor.CLOSE_PERIOD]: Yup.number().typeError(provideNumberMessage),
          [EMonitor.OPEN_THRESHOLD]: Yup.number()
            .typeError(provideNumberMessage)
            .min(0, boundaries100Error)
            .max(100, boundaries100Error),
          [EMonitor.OPEN_PERIOD]: Yup.number().typeError(provideNumberMessage)
        })
      })
  });

const aifiSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.AIFI_ID]: Yup.number()
      .typeError(provideNumberMessage)
      .required(requiredMessage),
    [EComponentProperty.ENTRY_ID]: Yup.number()
      .typeError(provideNumberMessage)
      .required(requiredMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.STATE]: monitorSchema
      })
  });

const adienSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.TERMINAL_ID]: Yup.string().required(requiredMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema
      })
  });

const maintenanceBreakSchema = Yup.object().shape({
  [EMaintenanceBreak.TYPE]: Yup.string().required(requiredMessage),
  [EMaintenanceBreak.STARTS_AT]: Yup.string().required(requiredMessage),
  [EMaintenanceBreak.ENDS_AT]: Yup.string().required(requiredMessage)
});

const posServiceRequiredScheme = {
  [EComponentProperty.WORKER_ID]: Yup.string().required(requiredMessage),
  [EComponentProperty.POS_IP_ADDRESS]: Yup.string().required(requiredMessage),
  [EComponentProperty.POS_PORT]: Yup.number().required(requiredMessage),
  [EComponentProperty.POS_USERNAME]: Yup.string().required(requiredMessage),
  [EComponentProperty.POS_PASSWORD]: Yup.string().required(requiredMessage),
  [EComponentProperty.OUTAGE_THRESHOLD]: Yup.number().required(requiredMessage),
  [EComponentProperty.NO_OUTAGE_THRESHOLD]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage),
  [EComponentProperty.MAINTENANCE_BREAKS]: Yup.array()
    .nullable()
    .of(maintenanceBreakSchema)
};

const posServiceOptionalScheme = {
  [EComponentProperty.WORKER_ID]: Yup.string(),
  [EComponentProperty.POS_IP_ADDRESS]: Yup.string(),
  [EComponentProperty.POS_PORT]: Yup.number(),
  [EComponentProperty.POS_USERNAME]: Yup.string(),
  [EComponentProperty.POS_PASSWORD]: Yup.string(),
  [EComponentProperty.OUTAGE_THRESHOLD]: Yup.number(),
  [EComponentProperty.NO_OUTAGE_THRESHOLD]:
    Yup.number().typeError(provideNumberMessage),
  [EComponentProperty.MAINTENANCE_BREAKS]: Yup.array()
    .nullable()
    .of(maintenanceBreakSchema)
};

const iPosRequiredScheme = {
  [EComponentProperty.TRONITAG_ID]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage),
  [EComponentProperty.FAKTUROWNIA_ID]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
};

const iPosOptionalScheme = {
  [EComponentProperty.TRONITAG_ID]:
    Yup.number().typeError(provideNumberMessage),
  [EComponentProperty.FAKTUROWNIA_ID]:
    Yup.number().typeError(provideNumberMessage)
};

const posSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SERVICE_KEY]: Yup.string(),
    [EComponentProperty.TYPE]: Yup.string().required(requiredMessage),
    [EComponentProperty.POS_SERVICE]: Yup.object()
      .nullable()
      .when(EComponentProperty.TYPE, {
        is: EPosType.POS_SERVICE,
        then(scheme) {
          return scheme.shape(posServiceRequiredScheme);
        },
        otherwise(scheme) {
          return scheme.shape(posServiceOptionalScheme);
        }
      }),
    [EComponentProperty.IPOS]: Yup.object()
      .nullable()
      .when(EComponentProperty.TYPE, {
        is: EPosType.IPOS,
        then(scheme) {
          return scheme.shape(iPosRequiredScheme);
        },
        otherwise(scheme) {
          return scheme.shape(iPosOptionalScheme);
        }
      }),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema,
        [EComponentProperty.PAPER_STATE]: monitorSchema
      })
  });

const infokioskSchema = Yup.object()
  .required()
  .shape({
    [EComponentProperty.SCOPE]: Yup.string().required(requiredMessage),
    [EComponentProperty.SERVICE_KEY]: Yup.string(),
    [EComponentProperty.HEARTBEAT_THRESHOLD]:
      Yup.number().typeError(provideNumberMessage),
    [EComponentProperty.MONITORS]: Yup.object()
      .required()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema
      })
  });

const powerBouncerSchema = Yup.object()
  .optional()
  .shape({
    [EComponentProperty.TOKEN]: Yup.string(),
    [EComponentProperty.HELP_LINK]: Yup.string(),
    [EComponentProperty.HEARTBEAT_THRESHOLD]: Yup.number(),
    [EComponentProperty.IO_CONTROLLER]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.SERVICE_KEY]: Yup.string()
      }),
    [EComponentProperty.AGE_ESTIMATION]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.MIN_ADULT_AGE]: Yup.number()
      }),
    [EComponentProperty.MONITORS]: Yup.object()
      .optional()
      .shape({
        [EComponentProperty.AVAILABILITY]: monitorSchema,
        [EComponentProperty.QR_READER_AVAILABILITY]: monitorSchema
      })
  });

export const componentsSchema = Yup.object()
  .required()
  .shape({
    [EComponent.AIFI]: aifiSchema,
    [EComponent.ADYEN]: adienSchema,
    [EComponent.POS]: posSchema,
    [EComponent.INFOKIOSK]: infokioskSchema,
    [EComponent.POWER_BOUNCER]: powerBouncerSchema
  });
