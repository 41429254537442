import { StoreChainParameters } from "api/common/storeChainConfig";

import { cardIssuerActionsMapper } from "../common/cardIssuerActionsMapper";

export const parametersMapper = (parameters: StoreChainParameters) => {
  const mappedCardIssuerActions = cardIssuerActionsMapper(
    parameters?.cardIssuerActions
  );

  return {
    ...parameters,
    sms: {
      ...parameters?.sms,
      prefixConfig: parameters?.sms?.prefixConfig?.defaultChannel
        ? parameters?.sms?.prefixConfig
        : null
    },
    cardIssuerActions: mappedCardIssuerActions
  };
};
