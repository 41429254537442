import { TStore } from "types/configFields";

import { openingScheduleMapper } from "../response/openingSchedule";

import { componentsMapper } from "./components";
import { parametersMapper } from "./parameters";

export const payloadMapper = (payload: TStore): TStore => {
  const { parameters, components, openingSchedule, ...restPayload } = payload;

  const mappedParameters = parametersMapper(parameters);
  const mappedComponents = componentsMapper(components);
  const mappedSchedule = openingScheduleMapper(openingSchedule);

  return {
    ...restPayload,
    parameters: mappedParameters,
    components: mappedComponents,
    openingSchedule: mappedSchedule
  };
};
