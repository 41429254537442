import { toggleCommaStringToArray } from "utils";

import { CardIssuerAction, EMaintenanceBreakType } from "types/configFields";

export const replaceWithInitial = (
  initial: Record<string, any>,
  configPart?: Record<string, any> | null
) => (!configPart ? initial : configPart);

export const getTypeFromDatetime = (dateTimeString: string) => {
  const dailyRegex = /^\d{2}:\d{2}:\d{2}$/;
  const yearlyRegex = /^\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/;
  const oneTimeRegex = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}$/;

  if (dailyRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.DAILY;
  } else if (yearlyRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.YEARLY;
  } else if (oneTimeRegex.test(dateTimeString)) {
    return EMaintenanceBreakType.ONE_TIME;
  } else {
    return EMaintenanceBreakType.DAILY;
  }
};

export const cardIssuerActionsMapper = (
  cardIssuerActions?: CardIssuerAction[],
  isResponse?: boolean
) => {
  if (!cardIssuerActions) {
    return [];
  }

  return cardIssuerActions.map(({ bins, brands, countryCodes, action }) => {
    const toggledBins = toggleCommaStringToArray(bins);
    const toggledBrands = toggleCommaStringToArray(brands);
    const toggledCountryCodes = toggleCommaStringToArray(countryCodes);

    if (isResponse) {
      return {
        bins: bins ? toggledBins : "",
        brands: brands ? toggledBrands : "",
        countryCodes: countryCodes ? toggledCountryCodes : "",
        action
      };
    } else {
      return {
        bins: bins ? toggledBins : [],
        brands: brands ? toggledBrands : [],
        countryCodes: countryCodes ? toggledCountryCodes : [],
        action
      };
    }
  });
};
