import { Flex, Text } from "@chakra-ui/react";

import { BorderedBox } from "components/borderedBox/BorderedBox";
import { EFieldType } from "components/formField/FormField";
import StoreField from "pages/store/components/StoreField";
import {
  EStore,
  EStoreService,
  EStoreServiceProperty
} from "types/configFields";

import { headers } from "../../labels";
import { cardStyles } from "../../styles";

const Services = () => {
  const headingStyles = {
    fontSize: "1rem",
    fontWeight: "bold",
    m: "0"
  } as const;

  return (
    <>
      <BorderedBox mt="0">
        <Flex {...cardStyles}>
          <Text
            data-searchid={`${EStore.SERVICES}.${EStoreService.ERP}`}
            {...headingStyles}
          >
            {headers[`${EStore.SERVICES}.${EStoreService.ERP}`]}
          </Text>

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.STORE_ID}`}
          />
        </Flex>
      </BorderedBox>

      <BorderedBox mt="0">
        <Flex {...cardStyles}>
          <Text
            data-searchid={`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}`}
            {...headingStyles}
          >
            {headers[`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}`]}
          </Text>

          <StoreField
            fieldType={EFieldType.TEXT}
            name={`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}.${EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL}`}
          />
        </Flex>
      </BorderedBox>
    </>
  );
};

export default Services;
