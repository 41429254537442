import {
  ECLientPriority,
  PeriodRule,
  TStoreParameters
} from "types/configFields";
import { timeFormatter } from "utils/dateTime";

import { cardIssuerActionsMapper } from "../helpers";

const dailyPeriodRulesMapper = (dailyPeriodRules?: PeriodRule[]) => {
  if (!dailyPeriodRules) {
    return [];
  }

  return dailyPeriodRules.map(rule => ({
    [ECLientPriority.STARTS_AT]: timeFormatter(
      rule[ECLientPriority.STARTS_AT],
      true
    ),
    [ECLientPriority.ENDS_AT]: timeFormatter(
      rule[ECLientPriority.ENDS_AT],
      true
    ),
    [ECLientPriority.PRIORITY]: rule[ECLientPriority.PRIORITY]
  }));
};

export const parametersMapper = (parameters: TStoreParameters | null) => {
  if (!parameters) {
    return null;
  }

  const mappedDailyPeriodRules = dailyPeriodRulesMapper(
    parameters?.clientPriority?.dailyPeriodRules
  );
  const mappedCardIssuerActions = cardIssuerActionsMapper(
    parameters?.cardIssuerActions
  );

  return {
    ...parameters,
    clientPriority: {
      ...parameters?.clientPriority,
      dailyPeriodRules: mappedDailyPeriodRules
    },
    sms: {
      ...parameters?.sms,
      prefixConfig: parameters?.sms?.prefixConfig?.defaultChannel
        ? parameters.sms?.prefixConfig
        : null
    },
    cardIssuerActions: mappedCardIssuerActions
  };
};
