import {
  EParameters,
  EParameterProperty,
  EStore,
  EParameterPropertySms,
  EParameterSmsLanguage,
  ECardIssuerActions,
  EIssuerActionProperty,
  EStoreService,
  EStoreServiceProperty,
  EComponent,
  EComponentProperty,
  EMonitor,
  ESchedule,
  EDay,
  EProperty,
  ECLientPriority,
  PaymentMethodFields,
  EPrefixConfigFields,
  EPrefixFields,
  EParameterPropertySmsThreshold,
  EMaintenanceBreak
} from "types/configFields";

const MONITORS_PER_COMPONENT = {
  [EComponent.AIFI]: [EComponentProperty.STATE],
  [EComponent.ADYEN]: [EComponentProperty.AVAILABILITY],
  [EComponent.INFOKIOSK]: [EComponentProperty.AVAILABILITY],
  [EComponent.POS]: [
    EComponentProperty.AVAILABILITY,
    EComponentProperty.PAPER_STATE
  ],
  [EComponent.POWER_BOUNCER]: [
    EComponentProperty.AVAILABILITY,
    EComponentProperty.QR_READER_AVAILABILITY
  ],
  [EComponent.TOTEM]: []
};

const generateMonitorLabels = () => {
  const baseLabels = Object.values(EComponent)
    .map(
      componentName =>
        `${EStore.COMPONENTS}.${componentName}.${EComponentProperty.MONITORS}`
    )
    .map(componentKey => {
      const componentName = componentKey.split(".")[1] as EComponent;
      const monitorNames = MONITORS_PER_COMPONENT[componentName];

      return monitorNames.map(baseName => `${componentKey}.${baseName}`);
    })
    .flat();

  const monitorsLabels = baseLabels.reduce((acc, curr) => {
    return {
      ...acc,
      [`${curr}.${EMonitor.IS_ENABLED}`]: "Czy monitor jest aktywny",
      [`${curr}.${EMonitor.IS_DISABLED_OUTSIDE_WORKING_HOURS}`]:
        "Czy monitor jest wyłączony poza godzinami pracy sklepu"
    };
  }, {});

  const actionsLabels = baseLabels.reduce((acc, curr) => {
    return {
      ...acc,
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.IS_ACTIVE}`]:
        "Czy akcja jest aktywna",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.CLOSE_THRESHOLD}`]:
        "Wartość graniczna procentowej niedostępności komponentu w danym okresie (zdefiniowanego przez pole close_period), warunkująca zamknięcie sklepu. (%)",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.CLOSE_PERIOD}`]:
        "Okres weryfikacji stanu komponentu, będący podstawą do zamknięcia sklepu. (s)",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.OPEN_THRESHOLD}`]:
        "Wartość graniczna procentowej dostępności komponentu w danym okresie (zdefiniowanego przez pole open_period), warunkująca otwarcie sklepu. (%)",
      [`${curr}.${EMonitor.ACTIONS}.${EMonitor.STORE_STATE_MODIFIER}.${EMonitor.OPEN_PERIOD}`]:
        "Okres weryfikacji stanu komponentu, będący podstawą do otwarcia sklepu. (s)"
    };
  }, {});

  return { ...monitorsLabels, ...actionsLabels };
};

const generateHoursLabels = () => {
  // `${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}.${day}`
  const days = Object.values(EDay);

  const baseLabels = days.map(
    day => `${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}.${day}`
  );

  const hoursLabels = baseLabels.reduce((acc, curr) => {
    return {
      ...acc,
      [`${curr}.${EProperty.OPENS_AT}`]: "otwarcie",
      [`${curr}.${EProperty.CLOSES_AT}`]: "zamknięcie"
    };
  }, {});

  return hoursLabels;
};

export const labels = {
  // general
  [EStore.NAME]: "Nazwa sieci",
  [EStore.CHAIN_ID]: "Identyfikator sieci sklepów",
  [EStore.CREATED_AT]: "Data utworzenia",

  // parameters
  [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.TYPE}`]:
    "Typ dokumentu potwierdzającego dokonanie zakupów",
  [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.DOMAIN}`]:
    "Domena, pod którą udostępniony jest dokument potwierdzający dokonanie zakupów",
  [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}.${EParameterProperty.MINI_ACCOUNT_TYPE}`]:
    "Typ minikonta. W przypadku braku, klient zostanie skierowany do domyślnego minikonta (typ PL).",
  [`${EStore.PARAMETERS}.${EParameters.DEFAULT_LANGUAGE}`]:
    "Domyślny język wykorzystywany do komunikacji z klientem",
  [`${EStore.PARAMETERS}.${EParameters.COUNTRY_CODE}`]:
    "Kod kraju działania sieci sklepów",
  [`${EStore.PARAMETERS}.${EParameters.DEFAULT_PREAUTH_AMOUNT}`]:
    "Domyślna kwota preautoryzacji w pomniejszej walucie (np grosze, eurocenty itp)",
  [`${EStore.PARAMETERS}.${EParameters.APP_PREAUTH_ENABLED}`]:
    "Flaga sterująca włączeniem / wyłączeniem mechanizmu preautoryzacji dla wejść “na aplikację”.",
  [`${EStore.PARAMETERS}.${EParameters.CURRENCY}`]: "Waluta obsługująca sklepy",
  [`${EStore.PARAMETERS}.${EParameters.PHONE_BLACKLIST_ID}`]:
    "Identyfikator blacklisty numerów telefonów",
  [`${EStore.PARAMETERS}.${EParameters.TIME_ZONE}`]:
    "Strefa czasowa, w której operuje siec sklepów, zapisana jako przesunięcie czasowe względem UTC np: +01:00",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.ORDERS_RULES}.0.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.ORDERS_RULES}.0.${ECLientPriority.ORDERS_THRESHOLD}`]:
    "Graniczna liczba zakupów klienta, do której klient klasyfikuje się do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}.0.${ECLientPriority.STARTS_AT}`]:
    "Czas rozpoczęcia okresu (każdego dnia), w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}.0.${ECLientPriority.ENDS_AT}`]:
    "Czas zakończenia okresu (każdego dnia), w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}.0.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.STARTS_AT}`]:
    "Dokładny dzień i czas rozpoczęcia okresu, w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.ENDS_AT}`]:
    "Dokładny dzień i czas zakończenia okresu, w którym nadawany jest ustalony priorytet klientom",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}.0.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.STORE_RULE}.${ECLientPriority.PRIORITY}`]:
    "Priorytet dla klientów zaklasyfikowanych do danej reguły",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}.${ECLientPriority.ENABLED}`]:
    "Flaga określająca czy reguła nadawania priorytetów dla klientów VIP jest włączona lub nie",

  [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_EXISTING_CLIENTS}`]:
    "Flaga określająca czy aktywacja konta (w procesie na kartę) jest niezbędna dla wszystkich istniejących kont klientów",
  [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}.${EParameterProperty.REQUIRE_FOR_NEW_CLIENTS}`]:
    "Flaga określająca czy aktywacja konta (w procesie na kartę) jest niezbędna dla nowo utworzonych kont klientów",

  // SMS
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.DEFAULT_CHANNEL}`]:
    "Domyślny kanał do wysyłki wiadomości",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.PREFIX}`]:
    "Prefix numeru telefonu, np: +48",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}.0.${EPrefixFields.CHANNEL}`]:
    "Kanał do wysyłki wiadomości",
  [`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}.0.${EParameterSmsLanguage.PREFIX}`]:
    "Prefix numeru telefonu, np: +48",
  [`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}.0.${EParameterSmsLanguage.LANGUAGE}`]:
    "Kod języku wykorzystywanego do komunikacji dla danego prefixu, np: PL",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.WELCOME_SMS}`]:
    "powitalnej (po utworzeniu użytkownika)",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.RECEIPT_SMS}`]:
    "z linkiem do paragonu",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.EXIT_SMS}`]:
    "po wyjściu klienta",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_FAILED_SMS}`]:
    "o nieudanej płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PBL_REQUESTED_SMS}`]:
    "z linkiem do płatności (w sytuacji ręcznego wymuszenia wysłania linku przez klienta)",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.ACTIVATION_SMS}`]:
    "z kodem aktywacyjnym",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.AUTO_RESCUE_INIT_SMS}`]:
    "o rozpoczęciu procesu AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS}`]:
    "o pierwszej próbie AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS}`]:
    "o finalnej próbie AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER1_SMS}`]:
    "z pierwszym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER2_SMS}`]:
    "z drugim przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER3_SMS}`]:
    "z trzecim przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER4_SMS}`]:
    "z czwartym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySms.PAYMENT_REMINDER5_SMS}`]:
    "z piątym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.AUTO_RESCUE_INIT_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości o rozpoczęciu procesu AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.FIRST_RESCUE_ATTEMPT_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości o pierwszej próbie AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.LAST_RESCUE_ATTEMPT_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości o finalnej próbie AutoRescue",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.PAYMENT_REMINDER1_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości z pierwszym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.PAYMENT_REMINDER2_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości z drugim przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.PAYMENT_REMINDER3_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości z trzecim przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.PAYMENT_REMINDER4_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości z czwartym przypomnieniem o płatności",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterPropertySmsThreshold.PAYMENT_REMINDER5_SMS_THRESHOLD}`]:
    "Kwota graniczna nieopłaconego zamówienia, dla której następuje wysyłka wiadomości z czwartym przypomnieniem o płatności",

  [`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.IMPORT_IMAGES_FROM_PIM}`]:
    "Flaga określająca czy podczas importu produktów należy dokonać jednoczesnego importu obrazów z usługi PIM Service",
  [`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}.${EParameterProperty.AIFI_IMPORT_ENABLED}`]:
    "Flaga określająca czy w ramach importu produktów następuje import do usługi AiFi.",

  [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.0.${ECardIssuerActions.BINS}`]:
    "Lista numerów BIN issuera karty, którego dotyczy akcja (po przecinku, bez spacji)",
  [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.0.${ECardIssuerActions.BRANDS}`]:
    "Lista brandów issuera karty, którego dotyczy akcja (po przecinku, bez spacji)",
  [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.0.${ECardIssuerActions.COUNTRY_CODES}`]:
    "Lista kodów kraju issuera karty, którego dotyczy akcja (po przecinku, bez spacji)",
  [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.TYPE}`]:
    "Typ akcji",
  [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}.0.${ECardIssuerActions.ACTION}.${EIssuerActionProperty.PREAUTH_AMOUNT}`]:
    "Nowa kwota preautoryzacji",
  [`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}.0.${PaymentMethodFields.NAME}`]:
    "Nazwa metody płatności",
  [`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}.0.${PaymentMethodFields.TYPE}`]:
    "Typ metody płatności",
  [`${EStore.PARAMETERS}.${EParameters.COMPLAINTS}.${EParameterProperty.HANDLER}`]:
    "Typ mechanizmu obsługi reklamacji. Domyślnie LEGACY",
  [`${EStore.PARAMETERS}.${EParameters.COMPLAINTS}.${EParameterProperty.TITLE_PREFIX}`]:
    "Prefix tytułu reklamacji w systemie obsługi",

  [`${EStore.SERVICES}.${EStoreService.SHORTENER}.${EStoreServiceProperty.DOMAIN}`]:
    "Domena w ramach, której shortener buduje URLe",
  [`${EStore.SERVICES}.${EStoreService.SHORTENER}.${EStoreServiceProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi - Shortener",
  [`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi - ERP",
  [`${EStore.SERVICES}.${EStoreService.ERP}.${EStoreServiceProperty.IS_ENABLED}`]:
    "Flaga określająca czy dla danej sieci sklepów jest włączona integracja z systemem ERP",
  [`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}.${EStoreServiceProperty.VERIFICATION_APPROVER_EMAIL}`]:
    "verification approver email",
  [`${EStore.SERVICES}.${EStoreService.SYNERISE}.${EStoreServiceProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi (Wskazuje na LEGACY WORKSPACE SNRS)",
  [`${EStore.SERVICES}.${EStoreService.SYNERISE}.${EStoreServiceProperty.SUPER_LOGIN_SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi (Wskazuje na SUPER LOGIN WORKSPACE SNRS)",

  [`${EStore.COMPONENTS}.${EComponent.AIFI}.${EComponentProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi - AiFi",
  [`${EStore.COMPONENTS}.${EComponent.ADYEN}.${EComponentProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi - Adyen",
  [`${EStore.COMPONENTS}.${EComponent.ADYEN}.${EComponentProperty.MERCHANT_ACCOUNT}`]:
    "Identyfikator konta Adyen",
  [`${EStore.COMPONENTS}.${EComponent.ADYEN}.${EComponentProperty.DEFAULT_LOCALE}`]:
    "Domyślny locale wykorzystywany przez Adyena",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi - POS",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.TYPE}`]:
    "Typ systemu POS",

  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.IPOS}.${EComponentProperty.VAT_RATES}`]:
    "Stawki VAT (po przecinku, bez spacji)",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.TYPE}`]:
    "Typ przerwy serwisowej",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.STARTS_AT}`]:
    "Początek okresu przerwy serwisowej",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}.0.${EMaintenanceBreak.ENDS_AT}`]:
    "Koniec okresu przerwy serwisowej",

  [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}.${EComponentProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi - Infokiosk",
  [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}.${EComponentProperty.HEARTBEAT_THRESHOLD}`]:
    "Czas graniczny dostarczenia heartbeatów przez infokiosk. W przypadku przekroczenia tego czasu uznaje się infokiosk jako niedziałający (s)",

  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.HELP_LINK}`]:
    "Link do pomocy",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.HEARTBEAT_THRESHOLD}`]:
    "Czas graniczny (w sekundach) dostarczenia heartbeatów przez aplikację power-bouncer. W przypadku przekroczenia tego czasu uznaje się aplikację jako niedziałającą.",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.AIFI_ZONE_ID}`]:
    "Identyfikator strefy AiFi w której znajduje się urządzenie dostępowe",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.IO_CONTROLLER}.${EComponentProperty.SERVICE_KEY}`]:
    "Klucz Azure App Configuration wskazujący na obiekt Key Vault zawierający dane usługi - IO Controller",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}.${EComponentProperty.AGE_ESTIMATION}.${EComponentProperty.MIN_ADULT_AGE}`]:
    "Minimalny wiek usługi estymacji wieku, przy którym osoba jest uznawana za pełnoletnią",
  [`${EStore.COMPONENTS}.${EComponent.TOTEM}.${EComponentProperty.AIFI_ZONE_ID}`]:
    "Identyfikator strefy AiFi w której znajduje się totem",

  [`${EStore.OPENING_SCHEDULE}.${ESchedule.IS_ENABLED}`]:
    "Flaga określająca czy harmonogram dla sklepu jest włączony lub nie",
  [`${EStore.OPENING_SCHEDULE}.${ESchedule.ADJUSTED_DAYS}`]:
    "Lista dni, w które sklep jest otwarty w innych godzinach niż wynika z bazowego harmonogramu",
  [`${EStore.OPENING_SCHEDULE}.${ESchedule.CLOSED_DAYS}`]:
    "Lista dni, w które sklepy jest zamknięty",

  [`day.${EDay.MON}`]: "Poniedziałek",
  [`day.${EDay.TUE}`]: "Wtorek",
  [`day.${EDay.WED}`]: "Środa",
  [`day.${EDay.THU}`]: "Czwartek",
  [`day.${EDay.FRI}`]: "Piątek",
  [`day.${EDay.SAT}`]: "Sobota",
  [`day.${EDay.SUN}`]: "Niedziela",

  ...generateMonitorLabels(),
  ...generateHoursLabels()
};

export const headers = {
  general: "Informacje ogólne",

  [EStore.PARAMETERS]: "Parametry konfiguracyjne sklepu",
  [EStore.SERVICES]: "Dane konfiguracyjne usług zewnętrznych",
  [EStore.COMPONENTS]: "Konfiguracja komponentów w sklepie",
  [EStore.OPENING_SCHEDULE]: "Harmonogram otwarcia sklepu",

  [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`]:
    "Konfiguracja dotycząca dokumentu potwierdzającego dokonanie zakupów, wysyłanego do klienta",
  [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`]:
    "Dane dotyczące aktywacji konta (w procesie na kartę)",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`]:
    "Prefixy numerów telefonów i powiązane z nimi konfiguracje wysyłki wiadomości",
  [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}.${EPrefixConfigFields.PREFIXES}`]:
    "Lista konfiguracji prefixów",
  [`${EStore.PARAMETERS}.${EParameters.SMS_LANGUAGES}`]:
    "Lista języków komunikacji wraz z przyporządkowanymi prefixami numerów telefonów",
  [`${EStore.PARAMETERS}.${EParameters.SMS}`]:
    "Konfiguracja wysyłki wiadomości SMS w dla danego sklepu (dla procesu 'na kartę')",
  [`${EStore.PARAMETERS}.${EParameters.PRODUCT_IMPORT}`]:
    "Konfiguracja importu produktów",
  [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`]:
    "Lista akcji dla konkretnych issuerów kart → w ramach schematu wejścia 'na kartę'",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]:
    "Dane konfiguracyjne systemu priorytetowego traktowania klientów",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.ORDERS_RULES}`]:
    "Dane konfiguracyjne reguły nadawania priorytetów dla klientów nieprzekraczających określonej granicznej ilości zakupów",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.DAILY_PERIOD_RULES}`]:
    "Dane konfiguracyjne reguł nadawania priorytetów dla klientów w konkretnych godzinach (niezależnie od dnia)",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.PERIOD_RULES}`]:
    "Dane konfiguracyjne reguł nadawania priorytetów dla klientów dla konkretnego pojedynczego okresu czasu",
  [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}.${ECLientPriority.VIP_RULE}`]:
    "Dane konfiguracyjne reguły nadawania priorytetów dla klientów VIP",
  [`${EStore.PARAMETERS}.${EParameters.RETRY_PAYMENT_METHODS}`]:
    "Lista metod płatności dla ręcznych ponowień płatności (w SuperApp)",
  [`${EStore.PARAMETERS}.${EParameters.COMPLAINTS}`]:
    "Dane konfiguracyjne systemu obsługi reklamacji",

  [`${EStore.SERVICES}.${EStoreService.ERP}`]: "ERP",
  [`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}`]: "Nanomonitor",
  [`${EStore.SERVICES}.${EStoreService.SHORTENER}`]: "Shortener",
  [`${EStore.SERVICES}.${EStoreService.SYNERISE}`]: "Synerise",

  [`${EStore.COMPONENTS}.${EComponent.AIFI}`]: "Aifi",
  [`${EStore.COMPONENTS}.${EComponent.ADYEN}`]: "Adyen",
  [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`]: "Infokiosk",
  [`${EStore.COMPONENTS}.${EComponent.POS}`]: "POS",
  [`${EStore.COMPONENTS}.${EComponent.POS}.${EComponentProperty.POS_SERVICE}.${EComponentProperty.MAINTENANCE_BREAKS}`]:
    "Lista zdefiniowanych przerw serwisowych dla danego sklepu.",
  [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`]: "Power bouncer",
  [`${EStore.COMPONENTS}.${EComponent.TOTEM}`]: "Totem marketingowy",

  [`${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}`]:
    "Bazowy tygodniowy harmonogram",
  [`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`]:
    "Wyjątki w harmonogramie"
};
