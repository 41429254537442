import { ReactNode, useState } from "react";
import { Box, Flex, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useField } from "formik";

import { useFieldGenerationContext } from "contexts/FieldGenerationContext";
import { cardStyles } from "pages/store/styles";

interface TogglableFormPartProps {
  name: string;
  headerText: string;
  children: ReactNode;
}

const TogglableFormPart = ({
  name,
  headerText,
  children
}: TogglableFormPartProps) => {
  const [field, , { setValue }] = useField(name);
  const value = field.value;
  const { isReadOnly } = useFieldGenerationContext();
  const [lockedValue, setLockedValue] = useState<any>(value);
  const [isChecked, setIsChecked] = useState(true);

  const handleChange = () => {
    if (isChecked) {
      setLockedValue(value);
      setValue(null);
    } else {
      setValue(lockedValue);
      setLockedValue(null);
    }
    setIsChecked(prev => !prev);
  };

  return (
    <Flex {...cardStyles} data-searchid={name}>
      {!isReadOnly && (
        <Flex alignItems="center">
          <Switch
            size="md"
            colorScheme="blue"
            isChecked={isChecked}
            onChange={handleChange}
          />
          <FormLabel htmlFor={name} mb="0" ml="1rem" cursor="pointer">
            {`Sekcja ${isChecked ? "włączona" : "wyłączona"}`}
          </FormLabel>
        </Flex>
      )}
      <Text fontSize="1rem" fontWeight="bold" m="0">
        {headerText}
      </Text>
      <Flex {...cardStyles} position="relative">
        {!isChecked && (
          <Box
            w="200px"
            p="10px"
            textAlign="center"
            alignContent="center"
            background="linear-gradient(90deg, rgba(28,0,0,0.97) 0%, rgba(121,9,9,0.929030987394958) 100%)"
            borderRadius="15px"
            color="red"
            position="absolute"
            left="40%"
            bottom="40%"
            zIndex={1}
          >
            Sekcja wyłączona (wartość null)
          </Box>
        )}
        <Flex
          filter={!isChecked ? "blur(2px)" : "none"}
          zIndex={1}
          gap="1.5rem"
          flexDir="column"
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TogglableFormPart;
