import {
  EComponent,
  EParameterProperty,
  EParameters,
  ESchedule,
  EStore,
  EStoreService
} from "types/configFields";

export const navigationStructure: any = {
  general: {},
  parameters: {
    subSections: {
      [`${EStore.PARAMETERS}.${EParameters.PURCHASE_CONFIRMATION_DOC}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.ACTIVATION}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.SMS}.${EParameterProperty.PREFIX_CONFIG}`]:
        {},
      [`${EStore.PARAMETERS}.${EParameters.SMS}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.CARD_ISSUER_ACTIONS}`]: {},
      [`${EStore.PARAMETERS}.${EParameters.CLIENT_PRIORITY}`]: {}
    }
  },
  services: {
    subSections: {
      [`${EStore.SERVICES}.${EStoreService.ERP}`]: {},
      [`${EStore.SERVICES}.${EStoreService.NANO_MONITOR}`]: {}
    }
  },
  components: {
    subSections: {
      [`${EStore.COMPONENTS}.${EComponent.AIFI}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.ADYEN}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.INFOKIOSK}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.POS}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.POWER_BOUNCER}`]: {},
      [`${EStore.COMPONENTS}.${EComponent.TOTEM}`]: {}
    }
  },
  openingSchedule: {
    subSections: {
      [`${EStore.OPENING_SCHEDULE}.${ESchedule.BASE}`]: {},
      [`${EStore.OPENING_SCHEDULE}.${ESchedule.EXCEPTIONS}`]: {}
    }
  }
};

export const menuItemStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  fontSize: ".8rem",
  p: ".125rem 1rem",
  _hover: {
    cursor: "pointer",
    backgroundColor: "gray.200"
  }
} as const;
