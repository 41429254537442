import * as Yup from "yup";

import {
  ECardIssuerActions,
  ECLientPriority,
  EIssuerActionProperty,
  EIssuerActionType,
  EParameterProperty,
  EParameterPropertySms,
  EParameterPropertySmsThreshold,
  EParameters,
  EPrefixConfigFields,
  EPrefixFields
} from "types/configFields";

import { provideNumberMessage, requiredMessage } from "./consts";

const boundariesError = "Ustaw wartość pomiędzy 0 a 10";
const actionsMinimumOneFieldRequired =
  "Co najmniej jedno z pól bins, brands, countryCodes jest wymagane";

const ordersRulesSchema = Yup.object().shape({
  [ECLientPriority.ORDERS_THRESHOLD]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
    .min(0, boundariesError)
    .max(10, boundariesError)
});

const periodRulesSchema = Yup.object().shape({
  [ECLientPriority.STARTS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.ENDS_AT]: Yup.string().required(requiredMessage),
  [ECLientPriority.PRIORITY]: Yup.number()
    .typeError(provideNumberMessage)
    .required(requiredMessage)
    .min(0, boundariesError)
    .max(10, boundariesError)
});

const prefixConfigSchema = Yup.object().shape({
  [EPrefixFields.PREFIX]: Yup.string().required(requiredMessage),
  [EPrefixFields.CHANNEL]: Yup.string().required(requiredMessage)
});

const cardIssuerActionSchema = Yup.object()
  .shape({
    [ECardIssuerActions.BINS]: Yup.string(),
    [ECardIssuerActions.BRANDS]: Yup.string(),
    [ECardIssuerActions.COUNTRY_CODES]: Yup.string(),
    [EIssuerActionProperty.ACTION]: Yup.object().shape({
      [EIssuerActionProperty.TYPE]: Yup.string().required(requiredMessage),
      [EIssuerActionProperty.PREAUTH_AMOUNT]: Yup.number()
        .nullable()
        .when(EIssuerActionProperty.TYPE, {
          is: (value: string) => value === EIssuerActionType.OVERRIDE_PREAUTH,
          then(schema) {
            return schema.required(requiredMessage);
          },
          otherwise(schema) {
            return schema;
          }
        })
    })
  })
  .test(
    "required bins, brands, countryCodes",
    actionsMinimumOneFieldRequired,
    function (values) {
      const { bins, brands, countryCodes } = values;

      if (!bins && !brands && !countryCodes) {
        return new Yup.ValidationError([
          new Yup.ValidationError(
            actionsMinimumOneFieldRequired,
            bins,
            `${this.path}.bins`
          ),
          new Yup.ValidationError(
            actionsMinimumOneFieldRequired,
            brands,
            `${this.path}.brands`
          ),
          new Yup.ValidationError(
            actionsMinimumOneFieldRequired,
            countryCodes,
            `${this.path}.countryCodes`
          )
        ]);
      }

      return true;
    }
  );

export const parametersSchema = Yup.object()
  .optional()
  .shape({
    [EParameters.PURCHASE_CONFIRMATION_DOC]: Yup.object().shape({
      [EParameterProperty.TYPE]: Yup.string().optional(),
      [EParameterProperty.DOMAIN]: Yup.string().optional()
    }),

    [EParameters.DEFAULT_LANGUAGE]: Yup.string().optional(),
    [EParameters.COUNTRY_CODE]: Yup.string().optional(),
    [EParameters.DEFAULT_PREAUTH_AMOUNT]: Yup.number()
      .typeError(provideNumberMessage)
      .optional(),
    [EParameters.APP_PREAUTH_ENABLED]: Yup.boolean().optional(),
    [EParameters.CURRENCY]: Yup.string().optional(),
    [EParameters.SMS]: Yup.object().shape({
      [EParameterProperty.PREFIX_CONFIG]: Yup.object()
        .optional()
        .shape({
          [EPrefixConfigFields.DEFAULT_CHANNEL]: Yup.string().test(
            "at-least-one-prefix-required",
            function (value, context) {
              const parent = this.parent;
              const grandparent = context.from ? context.from[1] : null;

              const prefixes = parent.prefixes;

              if (prefixes && prefixes.length > 0 && !value) {
                return this.createError({
                  message:
                    "Domyślny kanał wysyłki jest wymagany jeśli dodano co najmniej jedną konfigurację prefixów.",
                  path: `${this.path}`
                });
              } else if (grandparent && grandparent.value) {
                const hasPrefixConfig = Object.values(grandparent.value).some(
                  smsField => smsField === "PREFIX_CONFIG"
                );

                if (hasPrefixConfig && !value) {
                  return this.createError({
                    message:
                      "Co najmniej jedno pole konfiguracji SMS posiada wartość PREFIX_CONFIG. Podaj domyślny kanał wysyłki.",
                    path: `${this.path}`
                  });
                }

                return true;
              }

              return false;
            }
          ),
          [EPrefixConfigFields.PREFIXES]: Yup.array().of(prefixConfigSchema)
        }),
      [EParameterPropertySms.WELCOME_SMS]: Yup.string(),
      [EParameterPropertySms.RECEIPT_SMS]: Yup.string(),
      [EParameterPropertySms.EXIT_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_FAILED_SMS]: Yup.string(),
      [EParameterPropertySms.PBL_REQUESTED_SMS]: Yup.string(),
      [EParameterPropertySms.ACTIVATION_SMS]: Yup.string(),
      [EParameterPropertySms.AUTO_RESCUE_INIT_SMS]: Yup.string(),
      [EParameterPropertySms.FIRST_RESCUE_ATTEMPT_SMS]: Yup.string(),
      [EParameterPropertySms.LAST_RESCUE_ATTEMPT_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER1_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER2_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER3_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER4_SMS]: Yup.string(),
      [EParameterPropertySms.PAYMENT_REMINDER5_SMS]: Yup.string(),
      [EParameterPropertySmsThreshold.AUTO_RESCUE_INIT_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage),
      [EParameterPropertySmsThreshold.FIRST_RESCUE_ATTEMPT_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage),
      [EParameterPropertySmsThreshold.LAST_RESCUE_ATTEMPT_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage),
      [EParameterPropertySmsThreshold.PAYMENT_REMINDER1_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage),
      [EParameterPropertySmsThreshold.PAYMENT_REMINDER2_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage),
      [EParameterPropertySmsThreshold.PAYMENT_REMINDER3_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage),
      [EParameterPropertySmsThreshold.PAYMENT_REMINDER4_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage),
      [EParameterPropertySmsThreshold.PAYMENT_REMINDER5_SMS_THRESHOLD]:
        Yup.number().typeError(provideNumberMessage)
    }),
    [EParameters.CARD_ISSUER_ACTIONS]: Yup.array().of(cardIssuerActionSchema),
    [EParameters.CLIENT_PRIORITY]: Yup.object().shape({
      [ECLientPriority.ORDERS_RULES]: Yup.array().of(ordersRulesSchema),
      [ECLientPriority.DAILY_PERIOD_RULES]: Yup.array().of(periodRulesSchema),
      [ECLientPriority.PERIOD_RULES]: Yup.array().of(periodRulesSchema),
      [ECLientPriority.STORE_RULE]: Yup.object().shape({
        [ECLientPriority.PRIORITY]: Yup.number()
          .typeError(provideNumberMessage)
          .required(requiredMessage)
          .min(0, boundariesError)
          .max(10, boundariesError)
      })
    })
  });
