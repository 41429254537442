export function isProduction(): boolean {
  return process.env.REACT_APP_ENVIRONMENT === "prd";
}

export const getValue = ({
  values,
  name
}: {
  values: { [key: string]: any };
  name: string;
}) => {
  if (name.includes(".")) {
    const [mainKey, ...keyParts] = name.split(".");

    let value = values[mainKey];
    for (const keyPart of keyParts) {
      if (!keyPart) break;
      if (!value) value = "";
      value = value[keyPart as any];
    }

    return value;
  }

  return values[name];
};

export const toggleCommaStringToArray = (value: string | string[]) => {
  if (typeof value === "string") {
    return value.split(",");
  }

  return value.join(",");
};

export const convertCommaStringToObj = (input: string) => {
  const values = input.split(",");
  const output: Record<number, string> = {};

  values.forEach((value, index) => {
    output[index + 1] = value;
  });

  return output;
};
