import { Dispatch, SetStateAction, useState } from "react";
import { AddIcon, ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, Button, Checkbox, Collapse, Wrap } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";

import { PathParts } from "routes";
import { BorderedBox } from "components/borderedBox/BorderedBox";
import { CustomSelect } from "components/customSelect/CustomSelect";
import { FilterIcon } from "components/icons/FilterIcon";
import {
  flexStyles,
  gridStyles
} from "components/table/columnSwitcher/ColumnSwitcher";
import { SearchInput } from "components/table/searchInput/SearchInput";
import { useTableContext } from "contexts/TableContext";
import { useRoles } from "hooks/roles/useRoles";

import { getAvailableColumns, availableSearchQueries } from "../consts";
import { TSearchQueryProperties, EPatternType, TFilters } from "../types";

import { Filters } from "./Filters";

const searchQueries: Record<EPatternType, TSearchQueryProperties> = {
  externalStoreId: { displayName: "MPK", format: "STRING" },
  address: { displayName: "Adres", format: "STRING" },
  aifiId: { displayName: "AiFi ID", format: "NUMBER" },
  chainId: { displayName: "Sieć", format: "STRING" },
  scopeNanovo: {
    displayName: "Scope Nanovo",
    format: "STRING"
  }
};

type TopSectionProps = {
  searchValue: string;
  changeSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch: () => void;
  searchPatternType: EPatternType;
  setSearchPatternType: Dispatch<SetStateAction<EPatternType>>;
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
};

export const TopSection = ({
  searchValue,
  changeSearchValue,
  clearSearch,
  searchPatternType,
  setSearchPatternType,
  filters,
  setFilters
}: TopSectionProps) => {
  const { canEditStores } = useRoles();
  const { getCheckboxProps, setValue: setVisibleColumns } = useTableContext();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
  const availableColumns = getAvailableColumns();
  const boxStyles = availableColumns.length > 10 ? gridStyles : flexStyles;

  const options = availableSearchQueries.map(query => ({
    value: query,
    label: searchQueries[query].displayName
  }));

  const handlePatternTypeChange = (value: EPatternType) => {
    clearSearch();
    setSearchPatternType(value);
    setVisibleColumns(prev => {
      if (prev.includes(value)) {
        return prev;
      }
      return [...prev, value];
    });
  };

  const handleToggleFilters = () =>
    setIsFiltersOpen(prevIsFiltersOpen => !prevIsFiltersOpen);

  return (
    <Formik onSubmit={() => {}} initialValues={{}}>
      <Form>
        <Wrap justify="end">
          <CustomSelect
            value={searchPatternType}
            onChange={val => handlePatternTypeChange(val as EPatternType)}
            options={options}
            additionalStyles={{
              container: provided => ({
                ...provided,
                minWidth: "12rem"
              })
            }}
          />

          <SearchInput
            onChange={changeSearchValue}
            value={searchValue}
            onClear={clearSearch}
            type={
              searchQueries[searchPatternType].format === "NUMBER"
                ? "number"
                : "text"
            }
            placeholder="Szukaj sklepu"
          />

          <Button
            onClick={() => setIsColumnSelectorOpen(isOpen => !isOpen)}
            p="0 15px"
            justifySelf="end"
            bg="white"
            leftIcon={isColumnSelectorOpen ? <ViewOffIcon /> : <ViewIcon />}
          >
            Wybierz kolumny
          </Button>
          {canEditStores && (
            <Button
              as={Link}
              leftIcon={<AddIcon />}
              to={PathParts.CREATE}
              p="0 15px"
              bg="white"
            >
              Nowy sklep
            </Button>
          )}
          <Button
            onClick={handleToggleFilters}
            p="0 15px"
            leftIcon={<FilterIcon w=".75rem" />}
            variant="ghost"
          >
            Filtry
          </Button>
        </Wrap>

        <Box
          width="100%"
          as={Collapse}
          in={isColumnSelectorOpen}
          animateOpacity
          mt="1rem"
        >
          <BorderedBox p="5" mt="0.25rem">
            <Box {...boxStyles}>
              {availableColumns.map(option => (
                <Checkbox
                  key={option.value}
                  {...getCheckboxProps?.({
                    value: option.value
                  })}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Box>
          </BorderedBox>
        </Box>
        <Box
          as={Collapse}
          in={isFiltersOpen}
          animateOpacity
          overflow="visible !important"
        >
          <Filters {...{ filters, setFilters }} />
        </Box>
      </Form>
    </Formik>
  );
};
